import { signIn } from "next-auth/react";

export const googleSignIn = (callbackUrl?: string) => {
  signIn("google", callbackUrl ? { callbackUrl } : undefined);
};

export const googleSignInSync = (removeLocationSearch = false) => {
  signIn("google-scoped-auth", {
    callbackUrl:
      "/api/user/gmail/update_status" +
      (removeLocationSearch ? "" : window.location.search),
  });
};

export const addNewGoogleAccount = async () => {
  signIn(
    "google-scoped-auth",
    { callbackUrl: "/api/user/gmail/add_new_account" },
    { scope: "openid email https://www.googleapis.com/auth/gmail.readonly" }
  );
};

export const googleSignInReSync = (welcomeActionId: string, userId: string) => {
  signIn("google-scoped-auth", {
    callbackUrl: `/api/user/gmail/re_sync?welcomeActionId=${welcomeActionId}&userId=${userId}`,
  });
};
