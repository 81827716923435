import DialogContent from "./DialogContent";
import DialogDescription from "./DialogDescription";
import DialogHeader from "./DialogHeader";
import DialogTitle from "./DialogTitle";
import DialogFooter from "./DialogFooter";
import DialogOverlay from "./DialogOverlay";
import { Root, Trigger, Portal, Close } from "@radix-ui/react-dialog";

export {
  Root as Dialog,
  Trigger as DialogTrigger,
  Portal as DialogPortal,
  Close as DialogClose,
  DialogContent,
  DialogDescription,
  DialogHeader,
  DialogTitle,
  DialogFooter,
  DialogOverlay,
};
