import { SelectorType } from "@/components/section/selectorButton";
import { LoyaltyAccount } from "@/generated/email_parser.openapi";
import _ from "lodash";

export const brandTypeImages = [
  {
    brandType: "hotel",
    image: "/assets/hotel.svg",
    name: SelectorType.HOTEL,
  },
  {
    brandType: "airline",
    image: "/assets/icons/airplane.svg",
    name: SelectorType.AIRLINE,
  },
  {
    brandType: "card",
    image: "/assets/icons/credit-card.svg",
    name: SelectorType.CARD,
  },
];

export const getBrandTypeImage = (brandType: string) =>
  brandTypeImages.find((brand) => brand.brandType === brandType)?.image ?? "";

export const getBrandTypeName = (brandType: string) =>
  brandTypeImages.find((brand) => brand.brandType === brandType)?.name ??
  SelectorType.HOTEL;

export const filterUniqueLoyaltyPrograms = (
  loyaltyAccounts: LoyaltyAccount[]
) => {
  // Group accounts by loyaltyProgram
  const groupedByProgram = loyaltyAccounts.reduce<
    Record<string, LoyaltyAccount[]>
  >((acc, account) => {
    const key = account.loyaltyProgram || "unknown"; // Handle accounts without a loyaltyProgram
    if (!acc[key]) {
      acc[key] = [];
    }
    acc[key].push(account);
    return acc;
  }, {});

  // Process each group
  const uniqueAccounts = Object.values(groupedByProgram).map((accounts) => {
    // If any account in the group has the required fields, it takes precedence
    const accountWithFields = accounts.find(
      (account) =>
        account.loyaltyId ||
        account.redactedLoyaltyId ||
        account.loyaltyStatus ||
        account.loyaltyPoints ||
        account.parseInProgress
    );
    // If found, return that account, otherwise return the first account in the group
    return accountWithFields || accounts[0];
  });

  // Filter out accounts that don't meet the criteria
  return uniqueAccounts.filter(
    (account) =>
      !account.loyaltyId &&
      !account.redactedLoyaltyId &&
      !account.loyaltyStatus &&
      !account.loyaltyPoints &&
      !account.parseInProgress
  );
};

// filter where the no loyalty_id redacted_loyalty_id loyalty_status loyalty_points
export const filterOtherLoyaltyAccounts = (a: LoyaltyAccount) =>
  !a.loyaltyId &&
  !a.redactedLoyaltyId &&
  !a.loyaltyStatus &&
  !a.loyaltyPoints &&
  !a.parseInProgress;

export const filterLoyaltyAccounts = (a: LoyaltyAccount) =>
  a.loyaltyId ||
  a.redactedLoyaltyId ||
  a.loyaltyStatus ||
  a.loyaltyPoints ||
  a.parseInProgress;

export const sortLoyaltyAccounts = (
  acc1: LoyaltyAccount,
  acc2: LoyaltyAccount
) => {
  // Sorting Logic:
  // Priority 1. (loyaltyId or redactedLoyaltyId) and loyaltyStaus exist and (no loyaltyRequest available or loyaltycashValue >0)
  //   a. cashValue
  //   b. programTierIndex
  // Priority 2. loyaltyRequest not null
  //   a. loyaltyRequest.field_name == loyaltyId
  //   b. loyaltyRequest.field_name == loyaltyPoints
  // Priority 3. loyaltyId, redactedLoyaltyId, and loyaltyStaus null
  const acc1HasLoyaltyInfo =
    (acc1.loyaltyId || acc1.redactedLoyaltyId) &&
    acc1.loyaltyStatus &&
    (!acc1.loyaltyRequest?.loyaltyRequestAvailable || acc1.cashValue);
  const acc2HasLoyaltyInfo =
    (acc2.loyaltyId || acc2.redactedLoyaltyId) &&
    acc2.loyaltyStatus &&
    (!acc2.loyaltyRequest?.loyaltyRequestAvailable || acc2.cashValue);

  // Sort by cash values first. However, please be aware that when a cash value of 0 may be caused by
  // undefined loyalty points (i.e. we couldn't figure out the loyalty points); for these, we need to
  // push them towards the end of the list.
  if (_.isUndefined(acc1.loyaltyPoints)) {
    return 1;
  }
  if (_.isUndefined(acc2.loyaltyPoints)) {
    return -1;
  }
  if (
    !_.isUndefined(acc2.loyaltyPoints) &&
    !_.isUndefined(acc1.loyaltyPoints)
  ) {
    const cashValueDifference = (acc2.cashValue || 0) - (acc1.cashValue || 0);
    if (cashValueDifference !== 0) {
      return cashValueDifference;
    }
  }

  // And now, sort by other criteria. Anything from here on can only happen when the cash value difference is exactly 0.
  if (acc1HasLoyaltyInfo && !acc2HasLoyaltyInfo) {
    return -1;
  }
  if (!acc1HasLoyaltyInfo && acc2HasLoyaltyInfo) {
    return 1;
  }
  if (acc1HasLoyaltyInfo && acc2HasLoyaltyInfo) {
    const tierIndexDifference =
      (acc2.programTierIndex || 0) - (acc1.programTierIndex || 0);
    if (tierIndexDifference !== 0) {
      return tierIndexDifference;
    }
  }

  const acc1HasLoyaltyRequest = acc1.loyaltyRequest !== null;
  const acc2HasLoyaltyRequest = acc2.loyaltyRequest !== null;
  if (acc1HasLoyaltyRequest && !acc2HasLoyaltyRequest) {
    return -1;
  }
  if (!acc1HasLoyaltyRequest && acc2HasLoyaltyRequest) {
    return 1;
  }
  if (acc1HasLoyaltyRequest && acc2HasLoyaltyRequest) {
    const acc1LoyaltyId = acc1.loyaltyRequest?.fieldName === "loyalty_id";
    const acc2LoyaltyId = acc2.loyaltyRequest?.fieldName === "loyalty_id";
    if (acc1LoyaltyId && !acc2LoyaltyId) {
      return -1;
    }
    if (!acc1LoyaltyId && acc2LoyaltyId) {
      return 1;
    }
    const acc1LoyaltyPoints =
      acc1.loyaltyRequest?.fieldName === "loyalty_points";
    const acc2LoyaltyPoints =
      acc2.loyaltyRequest?.fieldName === "loyalty_points";
    if (acc1LoyaltyPoints && !acc2LoyaltyPoints) {
      return -1;
    }
    if (!acc1LoyaltyPoints && acc2LoyaltyPoints) {
      return 1;
    }
  }

  const acc1LacksLoyaltyInfo =
    !acc1.loyaltyId && !acc1.redactedLoyaltyId && !acc1.loyaltyStatus;
  const acc2LacksLoyaltyInfo =
    !acc2.loyaltyId && !acc2.redactedLoyaltyId && !acc2.loyaltyStatus;
  if (acc1LacksLoyaltyInfo && !acc2LacksLoyaltyInfo) {
    return 1;
  }
  if (!acc1LacksLoyaltyInfo && acc2LacksLoyaltyInfo) {
    return -1;
  }

  return 0;
};

export const loadingBgs = "animate-pulse rounded bg-[#523F32] bg-[#523F32]";
