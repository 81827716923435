import { cn } from "@/lib/utils";
import * as React from "react";

const DialogFooter: React.FC<React.HTMLAttributes<HTMLDivElement>> = ({
  className,
  ...props
}) => (
  <div
    className={cn("flex items-center justify-center", className)}
    {...props}
  />
);

DialogFooter.displayName = "DialogFooter";

export default DialogFooter;
