"use client";

import { useGlobalStore } from "@/app/context/globalStoreProvider";
import { Button } from "@/components/ui/button";
import {
  Dialog,
  DialogContent,
  DialogFooter,
  DialogHeader,
  DialogTitle,
} from "@/components/ui/dialog";
import { UsersUserIdWelcomeActionsWelcomeActionIdDeleteRequest } from "@/generated/email_parser.openapi";
import { googleSignInReSync } from "@/lib/googleUtils";
import { DialogProps } from "@radix-ui/react-dialog";
import { useSession } from "next-auth/react";
import React from "react";

const GmailReSyncModal: React.FC<DialogProps> = (props) => {
  const {
    welcomeActions: { data: welcomeActionData },
  } = useGlobalStore();
  const { data: session } = useSession();
  const welcomeActionId =
    welcomeActionData?.emailSyncTokenExpiredWelcomeAction?.id !== undefined
      ? welcomeActionData?.emailSyncTokenExpiredWelcomeAction?.id
      : null;

  const onDismiss = () => {
    deleteWelcomeAction();
    props.onOpenChange?.(false);
  };

  const deleteWelcomeAction = async () => {
    if (welcomeActionData?.emailSyncTokenExpiredWelcomeAction?.id) {
      const request: UsersUserIdWelcomeActionsWelcomeActionIdDeleteRequest = {
        userId: session?.user.id as string,
        welcomeActionId:
          welcomeActionData?.emailSyncTokenExpiredWelcomeAction.id,
      };
      await fetch("/api/user/welcome_actions/delete", {
        method: "POST",
        body: JSON.stringify(request),
      });
    }
  };

  return (
    <Dialog {...props} onOpenChange={onDismiss}>
      <DialogContent className="flex w-full max-w-[740px] flex-col items-center justify-center">
        <DialogHeader>
          <DialogTitle className="text-center text-[32px]">
            Keep your points up to date
          </DialogTitle>
        </DialogHeader>
        <div className="max-w-[480px] text-base font-normal leading-snug text-primary text-opacity-50">
          To maintain your data privacy and security, every six months we will
          ask you to update your Gmail connection to continue automatically
          tracking your points balance.{" "}
          <a href="/sync" className="text-blue-500 underline">
            You can read more here
          </a>
          .
        </div>
        <DialogFooter className="mt-2">
          <Button
            className="whitespace-nowrap"
            onClick={() => {
              googleSignInReSync(
                `${welcomeActionId}`,
                session?.user.id as string
              );
            }}
          >
            <img
              src="/assets/logos/google.svg"
              alt="Google"
              className="h-5 w-5"
            />
            Update Gmail
          </Button>
        </DialogFooter>
      </DialogContent>
    </Dialog>
  );
};

export default GmailReSyncModal;
