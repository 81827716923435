import { cn } from "@/lib/utils";
import React from "react";

type Props = {
  containerClassName?: string;
  bottomContent?: React.ReactNode;
};

const GondolaLoading: React.FC<Props> = ({
  containerClassName,
  bottomContent,
}) => {
  return (
    <div
      className={cn(
        "flex flex-col items-center justify-center gap-2",
        containerClassName
      )}
    >
      <div className="relative flex h-[140px] w-[140px] items-center justify-center rounded-full border border-[#DCA37A33]">
        <div className="absolute h-full w-full animate-spin-slow rounded-full border-8 border-transparent border-b-zinc-700"></div>
        <div className="z-10 flex items-center justify-center rounded-full bg-gradient-to-r from-[#222325] p-3">
          <img
            className="h-[62.10px] w-[62.10px]"
            src="/assets/logos/gondola.svg"
            alt="gondola-logo"
          />
        </div>
      </div>
      <div className="flex items-center justify-center text-primary">
        {bottomContent}
      </div>
    </div>
  );
};

export default GondolaLoading;
