import { cn } from "@/lib/utils";
import React, { HTMLAttributes, PropsWithChildren } from "react";

export enum SelectorType {
  AIRLINE = "AIRLINE",
  HOTEL = "HOTEL",
  CARD = "CARD",
}

type Props = PropsWithChildren<{
  name: string;
  icon: string;
  activeIcon?: string;
  first?: boolean;
  last?: boolean;
  active?: boolean;
  onClick?: () => void;
  className?: HTMLAttributes<HTMLDivElement>;
}>;

const SelectorButton: React.FC<Props> = ({
  icon,
  name,
  first,
  last,
  active,
  activeIcon,
  onClick,
  className,
}) => {
  return (
    <span
      className={cn(
        "flex h-full w-14 cursor-pointer items-center justify-center border-r border-zinc-700 px-[18px] py-[15px]",
        first ? "rounded-bl-[8px] rounded-tl-[8px]" : "",
        last ? "rounded-br-[8px] rounded-tr-[8px] border-r-0" : "",
        active ? "bg-primary-gold" : "",
        className
      )}
      onClick={onClick}
    >
      <img
        src={active ? activeIcon : icon}
        alt={name}
        className={cn("h-6 w-6", !active ? "opacity-80" : "")}
      />
    </span>
  );
};

export default SelectorButton;
