import * as React from "react";
import { SVGProps } from "react";

const ThumbsUp = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={36}
    height={36}
    fill="none"
    {...props}
  >
    <path
      stroke="#FDFBFB"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth={2.25}
      d="M9.949 15.375c1.21 0 2.3-.669 3.047-1.62a13.562 13.562 0 0 1 4.292-3.6c1.084-.577 2.023-1.434 2.48-2.573a6.75 6.75 0 0 0 .482-2.507v-.95c0-.621.504-1.125 1.125-1.125a3.375 3.375 0 0 1 3.375 3.375c0 1.727-.39 3.364-1.085 4.827-.398.837.16 1.923 1.088 1.923m0 0h4.689c1.54 0 2.918 1.041 3.081 2.572.067.634.102 1.277.102 1.928 0 4.271-1.488 8.195-3.974 11.282-.58.722-1.48 1.093-2.407 1.093H20.22a6.751 6.751 0 0 1-2.134-.346l-4.672-1.558a6.751 6.751 0 0 0-2.134-.346H8.856m15.897-14.625h-3.378M8.856 27.75c.125.307.26.608.405.903.296.6-.117 1.347-.785 1.347H7.114c-1.333 0-2.57-.777-2.958-2.052a17.998 17.998 0 0 1-.781-5.26c0-2.33.442-4.555 1.247-6.597.458-1.162 1.629-1.841 2.877-1.841h1.58c.707 0 1.117.834.75 1.439a13.437 13.437 0 0 0-1.954 6.998c0 1.79.348 3.5.981 5.063Z"
    />
  </svg>
);

export default ThumbsUp;
