import { cn } from "@/lib/utils";
import React, { HTMLAttributes, PropsWithChildren } from "react";

type Props = PropsWithChildren<{
  className?: HTMLAttributes<HTMLDivElement>;
}>;

const SelectorButtonContainer: React.FC<Props> = ({ children, className }) => {
  return (
    <div className={cn("flex rounded-[9px] border border-zinc-700", className)}>
      {children}
    </div>
  );
};

export default SelectorButtonContainer;
